<template>
  <Breadcrumb />
  <div
    class="accordion mb-3 mt-2"
    :id="String.format('Accordion_{0}', gridSettings.action)"
    ref="accordion"
  >
    <div class="accordion-item">
      <h2
        class="accordion-header"
        :id="String.format('Accordion_{0}_head', gridSettings.action)"
      >
        <button
          class="accordion-button"
          type="button"
          data-bs-toggle="collapse"
          :data-bs-target="
            String.format('#Accordion_{0}_tab1', gridSettings.action)
          "
          aria-expanded="true"
          :aria-controls="
            String.format('Accordion_{0}_tab1', gridSettings.action)
          "
        >
          <p class="text-capitalize fs-5 mb-0">
            {{
              $t(
                "RecordLogs.PageFilter",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </p>
        </button>
      </h2>
      <div
        :id="String.format('Accordion_{0}_tab1', gridSettings.action)"
        class="accordion-collapse collapse show"
        :aria-labelledby="
          String.format('Accordion_{0}_head', gridSettings.action)
        "
        :data-bs-parent="String.format('Accordion_{0}', gridSettings.action)"
      >
        <div class="accordion-body">
          <div class="row align-items-center">
            <div class="col-md-2">
              <label for="" class="required">{{
                $t(
                  "RecordLogs.CustomObject",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-10">
              <FormSelect
                :resetSelect="selectsReset"
                type="SelectWithRemoteDataAndInternalSearch"
                :isDetailIconShow="true"
                :routeLink="String.format('#/CustomObject/Detail/')"
                @onChange="onChangeCustomObject"
                :allowEmpty="false"
                :isParameters="false"
                :isGatewayRequest="true"
                requestUrl="/Lcdp-SummaryCustomObjects"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              <label for="RecordPublicId" class="">{{
                $t(
                  "RecordLogs.RecordPublicId",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-10">
              <input
                type="text"
                id="RecordPublicId"
                class="form-control"
                v-model="historyFieldRecordPublicId"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              <label for="" class="">{{
                $t(
                  "RecordLogs.ActionType",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-10">
              <FormSelect
                type="SelectWithLocalData"
                :resetSelect="selectsReset"
                :data="actionTypes"
                :allowEmpty="false"
                @onChange="onChangeForActionType"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              <label for="startDate" class="">{{
                $t(
                  "RecordLogs.StartDate",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-10">
              <DatePicker
                ref="startDate"
                id="startDate"
                type="datetime"
                :isReturnMaskedValue="true"
                :displayFormat="$store.getters._dateTimeFormat"
                @valueChanged="onValueChangedStartDate"
              />
            </div>
          </div>
          <div class="row mt-2 align-items-center">
            <div class="col-md-2">
              <label for="endDate" class="">{{
                $t(
                  "RecordLogs.EndDate",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}</label>
            </div>
            <div class="col-md-10">
              <DatePicker
                ref="endDate"
                id="endDate"
                type="datetime"
                :isReturnMaskedValue="true"
                :displayFormat="$store.getters._dateTimeFormat"
                @valueChanged="onValueChangedEndDate"
              />
            </div>
          </div>
          <div class="col-md-12 mt-2 text-end">
            <button
              type="button"
              class="btn btn-success btn-history-filter-search me-2"
              @click="onSearch('btn-history-filter-search')"
            >
              <span>
                {{
                  $t(
                    "RecordLogs.Search",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </span>
            </button>
            <button
              type="button"
              class="btn btn-history-filter-clear btn-danger"
              @click="onClear"
            >
              {{
                $t(
                  "RecordLogs.Clear",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="responseError.length > 0" class="alert alert-warning">
    <ul class="mb-0">
      <li v-for="error in responseError" v-bind:key="error">
        {{ error }}
      </li>
    </ul>
  </div>
  <Grid
    :settings="gridSettings"
    :columns="gridColumns"
    @onRequestFinally="onSearchEnd"
  />
  <HistoryDetailOrCompareModal />
</template>
<script>
import { createToast } from "mosha-vue-toastify";
import $ from "jquery";
import HistoryDetailOrCompareModal from "@/components/page-partials/record-logs/HistoryDetailOrCompareModal.vue";

export default {
  name: "RecordLogList",
  components: {
    HistoryDetailOrCompareModal,
  },
  data() {
    return {
      logRecordCustomObject: null, //grid relation property
      gridSettings: {
        action: "RecordLogs",
        requestUrl: "",

        isGatewayRequest: true,

        isCommandColumnHide: true,
        allowSearchPanel: true,
        allowExcelExport: true,
        allowPaging: true,

        autoOrderProcess: true,
      },
      gridColumns: [
        {
          text: this.$t(
            "Buttons.RecordView",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "recordPublicId",
          type: "string",
          visible: true,
          width: 0,
          template: "setXRMAppViewTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.User",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "userName",
          type: "string",
          visible: true,
          width: 0,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.ActionType",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "logType",
          type: "string",
          visible: true,
          width: 0,
          template: "recordLogActionTypeTemplate",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.Date",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "createdAt",
          type: "datetime",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.Source",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "requestFrom",
          type: "string",
          visible: true,
          width: 200,
          template: "",
          format: "",
          textAlign: "",
        },
        {
          text: this.$t(
            "RecordLogs.Information",
            {},
            { locale: this.$store.state.activeLang }
          ),
          field: "recordData",
          type: "string",
          visible: true,
          width: 200,
          template: "jsonViewerTemplate",
          format: "",
          textAlign: "",
        },
        // {
        //   text: this.$t(
        //     "RecordLogs.DifferenceBetweenTwoRecords",
        //     {},
        //     { locale: this.$store.state.activeLang }
        //   ),
        //   field: "",
        //   type: "string",
        //   visible: true,
        //   width: 200,
        //   template: "",
        //   format: "",
        //   textAlign: "",
        // },
      ],
      submitButtonHtml: String.format(
        '<span class="loading-block"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span><span> {0}</span></span>',
        this.$t(
          "Buttons.PleaseWait",
          {},
          { locale: this.$store.state.activeLang }
        )
      ),
      disabledButtonClasses:
        ".btn-history-filter-search, .btn-history-filter-clear",
      historyFilterEndDate: "",
      historyFilterStartDate: "",
      historyFieldActionType: "",
      historyFieldRecordPublicId: "",
      historyFieldCustomObjectPublicId: "",
      customObjects: [],
      actionName: "rws-GetRecordLogs",
      selectsReset: false,
      actionTypes: [
        {
          key: "1",
          value: this.$t(
            "RecordLogs.FieldServiceCreateRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "2",
          value: this.$t(
            "RecordLogs.FieldServiceUpdateRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "3",
          value: this.$t(
            "RecordLogs.FieldServiceDeleteRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "4",
          value: this.$t(
            "RecordLogs.FieldServiceUpdateFieldValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "5",
          value: this.$t(
            "RecordLogs.KanbanViewServiceUpdateFieldValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "6",
          value: this.$t(
            "RecordLogs.CalendarViewServiceUpdateFieldValue",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "7",
          value: this.$t(
            "RecordLogs.FieldServiceGetRecord",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "8",
          value: this.$t(
            "RecordLogs.FieldServiceExportRecords",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "9",
          value: this.$t(
            "RecordLogs.FieldServiceBulkUpdateRecords",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "10",
          value: this.$t(
            "RecordLogs.UserStatusUpdate",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
        {
          key: "11",
          value: this.$t(
            "RecordLogs.FieldServiceBulkImport",
            {},
            { locale: this.$store.state.activeLang }
          ),
        },
      ],
      pageData: {},
      historyLoaded: false,
      histories: null,
      responseError: [],
    };
  },
  mounted() {
    this.getCustomObjects();
  },
  methods: {
    onChangeCustomObject(selected) {
      this.historyFieldCustomObjectPublicId = selected.key;
    },
    onValueChangedEndDate(value) {
      this.historyFilterEndDate = value;
    },
    onValueChangedStartDate(value) {
      this.historyFilterStartDate = value;
    },
    getCustomObjects() {
      this.$prodGatewayAxios
        .get("/Lcdp-CustomObjectList?page=1&size=1000")
        .then((response) => {
          this.customObjects = response.data.items;
        })
        .catch(function (error) {
          //TODO:Error
          // alert(error);
        });
    },
    onSearch(buttonName) {
      var recordId = this.historyFieldRecordPublicId;
      if (String.isNullOrWhiteSpace(this.historyFieldCustomObjectPublicId)) {
        return;
      } else if (
        !String.isNullOrWhiteSpace(recordId) &&
        !String.isGuid(recordId)
      ) {
        createToast(
          this.$t(
            "FieldErrors.NoGuidFormat",
            {},
            { locale: this.$store.state.activeLang }
          ),
          {
            showIcon: "true",
            position: "top-right",
            type: "danger",
            transition: "zoom",
          }
        );
        return;
      }

      var button = $(String.format(".{0}", buttonName)),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);

      disabledButtons.prop("disabled", true);
      firstSpan.hide();
      if (loadingBlock.length === 0) {
        firstSpan.after(this.submitButtonHtml);
        loadingBlock = button.find(".loading-block");
      } else {
        loadingBlock.show();
      }

      this.logRecordCustomObject = this.customObjects.find(
        (f) => f.publicId == this.historyFieldCustomObjectPublicId
      );
      if (
        !this.logRecordCustomObject ||
        (!String.isNullOrWhiteSpace(this.logRecordCustomObject) &&
          String.isNullOrWhiteSpace(this.logRecordCustomObject.key))
      ) {
        createToast("Custom Object Key Error", {
          showIcon: "true",
          position: "top-right",
          type: "danger",
          transition: "zoom",
        });
        return;
      }

      var searchUrl = `${this.actionName}?customObjectKey=${this.logRecordCustomObject.key}`,
        requestParameters = [];

      if (!String.isNullOrWhiteSpace(recordId)) {
        requestParameters.push({ key: "recordPublicId", value: recordId });
      }

      var logTypeId = this.historyFieldActionType;
      if (!String.isNullOrWhiteSpace(logTypeId)) {
        requestParameters.push({ key: "logTypeId", value: logTypeId });
      }

      var startDate = $("#startDate").val();
      if (!String.isNullOrWhiteSpace(startDate)) {
        requestParameters.push({ key: "startDate", value: startDate });
      }

      var endDate = $("#endDate").val();
      if (!String.isNullOrWhiteSpace(endDate)) {
        requestParameters.push({ key: "endDate", value: endDate });
      }

      this.gridSettings.requestUrl = ""; //trigger watch parameter
      this.gridSettings.requestUrl = String.createGetUrl(
        searchUrl,
        requestParameters
      );
    },
    onSearchEnd() {
      var button = $(String.format(".{0}", "btn-history-filter-search")),
        firstSpan = button.find("span:first"),
        loadingBlock = button.find(".loading-block"),
        disabledButtons = $(this.disabledButtonClasses);
      firstSpan.show();
      loadingBlock.hide();
      disabledButtons.prop("disabled", false);
    },
    onChangeForActionType(selected) {
      this.historyFieldActionType = selected.key;
    },
    onClear() {
      this.selectsReset = !this.selectsReset;
      this.historyFieldRecordPublicId = "";
      this.$refs.startDate.$refs.datePicker.instance.reset();
      this.$refs.endDate.$refs.datePicker.instance.reset();
    },
  },
};
</script>
