<template>
  <div
    id="recordDetailHistoryViewModal"
    class="modal fade"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-hidden="true"
  >
    <div class="modal-dialog" id="sx-history-modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="sx-history-modal-title"></h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body" id="sx-history-modal-detail"></div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-dark btn-change-table-colors hidden"
            @click="changeTableColors"
          >
            <i class="fas fa-palette"></i>
            {{
              $t(
                "RecordLogs.InvertTableColors",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <button
            type="button"
            class="btn btn-danger btn-history-modal-close"
            data-bs-dismiss="modal"
          >
            <i class="bi bi-x"></i>
            {{
              $t("Buttons.Close", {}, { locale: this.$store.state.activeLang })
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
export default {
  mame: "HistoryDetailOrCompareModal",
  methods: {
    changeTableColors() {
      var trs = $(".table-history-compare").find("tr");
      trs.each(function (i, element) {
        var bdDark = $(element).find(".bd-dark");
        if (bdDark.length > 0) {
          bdDark
            .removeClass("bd-dark text-dark")
            .addClass("bg-dark text-white");
        } else {
          bdDark = $(element).find(".bg-dark");
          bdDark
            .addClass("bd-dark text-dark")
            .removeClass("bg-dark text-white");
        }
        var bdSuccess = $(element).find(".bd-success");
        if (bdSuccess.length > 0) {
          bdSuccess
            .removeClass("bd-success text-success")
            .addClass("bg-success text-white");
        } else {
          bdSuccess = $(element).find(".bg-success");
          bdSuccess
            .addClass("bd-success text-success")
            .removeClass("bg-success text-white");
        }
        var bdDanger = $(element).find(".bd-danger");
        if (bdDanger.length > 0) {
          bdDanger
            .removeClass("bd-danger text-danger")
            .addClass("bg-danger text-white");
        } else {
          bdDanger = $(element).find(".bg-danger");
          bdDanger
            .addClass("bd-danger text-danger")
            .removeClass("bg-danger text-white");
        }
      });
    },
  },
};
</script>
